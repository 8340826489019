
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from '@proman/rxjs-common';
import { Currency } from '../../interfaces/entity-interfaces';
import { CurrencyActions, setCurrency } from './currency.actions';
import { Store } from '@ngrx/store';
import { Entity } from '../../services/entity.service';
@Injectable()
export class CurrencyEffects {

    loadCurrencies$ = createEffect(() => this.actions$
            .pipe(
                ofType(CurrencyActions.LoadCurrency),
                tap(() => {
                    this.Entity.get('currency')
                        .search()
                        .then((payload: Currency[]) => {
                            this.store.dispatch(setCurrency({ payload }));
                        });
                })
            ),
        { dispatch: false }
    );

    constructor(
        private Entity: Entity,
        private store: Store,
        private actions$: Actions,
    ) {

    }

}
