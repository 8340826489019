import { Injectable } from '@angular/core';
import { Entity } from '@proman/services/entity.service';
import { resourcesConfig } from '@proman/resources';
import { TableField } from '@proman/interfaces/object-interfaces';
import { ProductEntityInterface } from '@proman/resources/product';

@Injectable()
export class ServicesService {
    productEntity: any;
    getExtraParameters = resourcesConfig['product'].params.extraParameters;

    constructor(
        private Entity: Entity,
    ) {
        this.productEntity = this.Entity.get('product');
    }
    getEntity = (): ProductEntityInterface => {
        return this.productEntity;
    };

    getFields = (): TableField[] => {
        return [
            {
                name: 'created_at',
                key: 'createdAt',
                formatter: 'dateTime'
            },
            {
                name: 'ID',
                key: 'id'
            },
            {
                name: 'name',
                key: 'name'
            },
            {
                name: 'cost',
                key: 'cost',
                formatter: 'money',
            },
            {
                name: 'price',
                key: 'price',
                formatter: 'money',
            },
            {
                name: 'purchase',
                key: 'purchase.id',
                state:  {
                    name: 'purchase',
                    key: 'purchase.id',
                    id: 'purchase.id'
                }
            },
            {
                name: 'status',
                key: 'status'
            },
            {
                name: 'category',
                key: 'purchase.category.name',
            }
        ];
    };

}
