import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer as currUserReducer, CurrUserEffects, currUserKey } from './curr-user';
import { reducer as currencyReducer, currencyKey } from './currency';
import { reducer as docsIdReducer, docsIdKey } from './docs-id';
import { reducer as systemOptionsReducer, SystemOptionsEffects, systemOptionsKey } from './system-options';
import { CurrencyEffects } from './currency/currency.effects';
import { reducer as utilsReducer, utilsKey } from './utils';
import { UtilsEffects } from './utils/utils.effects';

@NgModule({
    imports: [
        StoreModule.forFeature(currUserKey, currUserReducer),
        StoreModule.forFeature(currencyKey, currencyReducer),
        StoreModule.forFeature(docsIdKey, docsIdReducer),
        StoreModule.forFeature(systemOptionsKey, systemOptionsReducer),
        StoreModule.forFeature(utilsKey, utilsReducer),
        EffectsModule.forFeature([CurrUserEffects]),
        EffectsModule.forFeature([CurrencyEffects]),
        EffectsModule.forFeature([SystemOptionsEffects]),
        // EffectsModule.forFeature([UtilsEffects]),
    ]
})

export class PromanStoreModule {}
